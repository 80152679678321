<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="show"
        v-on:click:outside="cancelMethod"
    >
        <v-card>
            <v-toolbar
                color="primary"
                dark
                class="text-h5"
            >Modifica Assenza
                <v-spacer></v-spacer>
                <v-btn 
                    icon
                    @click="cancelMethod"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container fluid>
                <!-- <v-row no-gutters class="mt-4">
                    <v-col cols="1"/>
                    <v-col cols="5">
                        <v-card
                            class="ma-2 d-flex flex-row justify-center align-center text-h6"
                            :color="absenceKind == 'leave' ? 'primary' : 'grey lighten-2'"
                            :class="{'grey--text text--darken-1': absenceKind == 'holiday'}"
                            height="60"
                            :dark="absenceKind == 'leave'"
                            @click="absenceKind = 'leave'"
                            :elevation="absenceKind == 'leave' ? 0 : 2"
                        >
                            Permesso
                        </v-card>
                    </v-col>
                    <v-col cols="5">
                        <v-card
                            class="ma-2 d-flex flex-row justify-center align-center text-h6"
                            :class="{'grey--text text--darken-1': absenceKind == 'leave'}"
                            :color="absenceKind == 'holiday' ? 'primary' : 'grey lighten-2'"
                            height="60"
                            :dark="absenceKind == 'holiday'"
                            @click="absenceKind = 'holiday'"
                            :elevation="absenceKind == 'holiday' ? 0 : 2"
                        >
                            Ferie
                        </v-card>
                    </v-col>
                    <v-col cols="1"/>
                </v-row> -->
                <v-form ref="form">            
                    <v-row class="px-12 mt-4 d-flex justify-center">
                        <v-text-field
                            class="ml-2 mr-2"
                            type="number"
                            label="Ore"
                            outlined
                            v-model="editedTimeHours"
                        />
                        <v-text-field
                            class="ml-2 mr-2"
                            type="number"
                            label="Min"
                            outlined
                            v-model="editedTimeMinutes"
                            :rules="[minuteValueCheckRule]"
                            hint="Step minimo: 30 minuti"
                        />
                    </v-row>
                    <v-row class="d-flex justify-center mb-4 mt-0">
                        <v-checkbox
                            v-model="editedApply104"
                            label="Applica Legge 104"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                    </v-row>
                </v-form>
            </v-container>
            <v-card-actions class="justify-end">
                <v-btn
                    color="red lighten-2"
                    class="px-4"
                    @click="deleteMethod"
                >  
                    <v-icon left>
                        mdi-delete
                    </v-icon>
                    Elimina
                </v-btn>
                <v-btn
                    color="amber lighten-1"
                    class="px-4"
                    @click="saveMethod"
                    :disabled="saveDisabled"
                >
                    <v-icon left>
                        mdi-check
                    </v-icon>
                    Modifica
                </v-btn>
            </v-card-actions>   
        </v-card>  
    </v-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            editedTimeHours: null,
            editedTimeMinutes: null,
            editedApply104: false,
            absenceKind: null,
            minuteValueCheckRule: (val) => { return val % 30 == 0 || 'Valore non valido'; },
        }
    },
    props: {
        record: {
            type: Object,
            default: function() {
                return {}
            }
        },
        show: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        cancelMethod: function() {
            this.$emit('close-absence-record-edit-dialog');
        },
        deleteMethod: function() {
            this.$emit('close-absence-record-edit-dialog');
            this.$emit('delete-absence-record', this.record.dateIndex);
        },
        saveMethod: function() {
            if(this.checkMinValue())
                this.$emit('save-absence-record', {absenceKind: this.absenceKind, minuteDuration: this.computedAbsenceMinutes, apply104: this.editedApply104});
        },
        checkMinValue: function() {
            return this.$refs.form.validate() && this.computedAbsenceMinutes > 0; 
        },
    },
    watch: {
        record: {
            handler: function(val) {
                switch ((val || {}).recordType) {
                    case 'LEAVE_RECORD':
                        this.absenceKind = 'leave';
                        this.editedTimeMinutes = val.leaveRecord.minuteDuration % 60;
                        this.editedTimeHours = Math.floor(val.leaveRecord.minuteDuration / 60);
                        this.editedApply104 = val.leaveRecord.apply104;
                        break;
                    case 'HOLIDAY_RECORD':
                        this.absenceKind = 'holiday';
                        this.editedTimeMinutes = val.holidayRecord.minuteDuration % 60;
                        this.editedTimeHours = Math.floor(val.holidayRecord.minuteDuration / 60);
                        break;
                    default:
                        break
                }
               
            },
            deep: true
        }
    },
    computed: {
        computedAbsenceMinutes: function() {
            return (parseInt(this.editedTimeHours) * 60) + parseInt(this.editedTimeMinutes || 0);
        },
        recordDuration: function() {
            switch ((this.record || {}).recordType) {
                    case 'LEAVE_RECORD':
                    return this.record.leaveRecord.minuteDuration;
                case 'HOLIDAY_RECORD':
                    return this.record.holidayRecord.minuteDuration;
                default:
                    return 0;
            }
        },
        saveDisabled: function() {
            let absnceKindUnchanged = (this.absenceKind == 'leave' && this.record.recordType == 'LEAVE_RECORD') || (this.absenceKind == 'holiday' && this.record.recordType == 'HOLIDAY_RECORD');
            let durationUnchanged = this.recordDuration == this.computedAbsenceMinutes;
            let apply104Unchanged = this.editedApply104 == (this.record.recordType == 'LEAVE_RECORD' ? this.record.leaveRecord.apply104 : false);
            
            return absnceKindUnchanged && durationUnchanged && apply104Unchanged;
        }
    }
}
</script>