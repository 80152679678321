import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},on:{"click:outside":_vm.cancelMethod},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h5",attrs:{"color":"primary","dark":""}},[_vm._v("Modifica Assenza "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.cancelMethod}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form"},[_c(VRow,{staticClass:"px-12 mt-4 d-flex justify-center"},[_c(VTextField,{staticClass:"ml-2 mr-2",attrs:{"type":"number","label":"Ore","outlined":""},model:{value:(_vm.editedTimeHours),callback:function ($$v) {_vm.editedTimeHours=$$v},expression:"editedTimeHours"}}),_c(VTextField,{staticClass:"ml-2 mr-2",attrs:{"type":"number","label":"Min","outlined":"","rules":[_vm.minuteValueCheckRule],"hint":"Step minimo: 30 minuti"},model:{value:(_vm.editedTimeMinutes),callback:function ($$v) {_vm.editedTimeMinutes=$$v},expression:"editedTimeMinutes"}})],1),_c(VRow,{staticClass:"d-flex justify-center mb-4 mt-0"},[_c(VCheckbox,{attrs:{"label":"Applica Legge 104","color":"primary","hide-details":""},model:{value:(_vm.editedApply104),callback:function ($$v) {_vm.editedApply104=$$v},expression:"editedApply104"}})],1)],1)],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{staticClass:"px-4",attrs:{"color":"red lighten-2"},on:{"click":_vm.deleteMethod}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Elimina ")],1),_c(VBtn,{staticClass:"px-4",attrs:{"color":"amber lighten-1","disabled":_vm.saveDisabled},on:{"click":_vm.saveMethod}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-check ")]),_vm._v(" Modifica ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }